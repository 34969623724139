import React, { useEffect, useState } from 'react'
import { fetchFoundationPackUsers } from './foundationPackServices';
import { Toastify } from '../../components/toaster/Toastify';
import { FoundationNavbar } from '../../components/navbar/FoundationNavbar';
import { useParams, useNavigate } from "react-router-dom";
import { decryption } from '../../utils/bycrypt.helper';
import { foundationLoggedInUser } from '../../utils/foundationPackAuth';
import { FoundationPackTable } from './FoundationPackTable';
import styled from 'styled-components'

export const FoundationPackUsers = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [packUsers, setPackUsers] = useState([]);
  const [teamType, setTeamType] = useState("")
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const auth = async () => {
    try {
      let teamType = await decryption(params.teamType);
      let loggerInUser = await foundationLoggedInUser();
      if (teamType === loggerInUser.role) {
        setTeamType(params.teamType);
        fetchPackUsers(loggerInUser)
      } else {
        navigate('/*')
      }
    } catch (error) {
      navigate('/*')
    }
  }
  const fetchPackUsers = async (loggerInUser) => {
    setLoading(true);
    let packUser = await fetchFoundationPackUsers({ teamID: loggerInUser.id });
    if (packUser.status) {
      setLoading(false);
      setPackUsers(packUser.response);
    } else {
      setLoading(false);
      Toastify('error', 'Something went wrong')
    }
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'Email', // accessor is the "key" in the data
        Footer: "Email",
        disableFilters: true
      },
      {
        Header:"Team Email",
        accessor:"TeamEmail",
        Footer:"Team Email",
        disableFilters: true
      },
      {
        Header: 'Amount Paid',
        accessor: 'AmountPaid', // accessor is the "key" in the data
        Footer: "Amount Paid",
        disableFilters: true
      },
      {
        Header: 'Coupon Code',
        accessor: 'CouponCode',
        Footer: "Coupon Code",
        disableFilters: true
      },
      {
        Header: 'Pack Status',
        accessor: 'PackStatus',
        Footer: "Pack Status",
        Cell: ({ row: { original } }) => (
          original.PackStatus === "NOT ACTIVATED" ?
            <>
              <span class="badge bg-warning text-dark">{original.PackStatus}</span>
            </>
            :
            original.PackStatus === "PACK ACTIVATED" ?
              <>
                <span class="badge bg-success">{original.PackStatus}</span>
              </>
              :
              original.PackStatus === "EXPIRED" ?
                <>
                  <span class="badge bg-danger">{original.PackStatus}</span>
                </>
                :
                <>
                  <span class="badge bg-info text-dark">{original.packStatus}</span>
                </>
        )

      },
      {
        Header: 'Payment Date',
        accessor: 'PaymentDate',
        Footer: "Payment Date",
        disableFilters: true
      },
    ],
    []
  )
  const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      ${'' /* :first-child {
        td {
          border-top: 1px solid black;
        }
      } */}

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    tfoot {
      tr:first-child {
        td {
          border-top: 2px solid black;
        }
      }
      font-weight: bolder;
    }
  }
  .pagination button  {
    margin: 0px 3px;
  }
  .pagination {
    padding: 0.5rem;
  }
`
  return (
    <React.Fragment>
      <FoundationNavbar teamType={teamType} />
      <div className='foundationPackUsers'>
        {loading ? "Loading..." :
          <>
            {packUsers.length > 0 ?
              <Styles>
                <FoundationPackTable columns={columns} data={packUsers} />
              </Styles>
              :
              <div className="nodatafound" style={{ textAlign: "center", marginTop: "10px" }}>
                <h2>No data found</h2>
              </div>
            }
          </>
        }
        {/* <div className='container'>
          <table className="table table-bordered" style={{ marginTop: "15px" }}>
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {packUsers.map((packUser) => {
                return (
                  <tr key={packUser.id}>
                    <td>{packUser.id}</td>
                    <td>{packUser.email}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </React.Fragment>
  )
}
