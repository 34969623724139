import React, { useRef } from 'react'
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table'
import { CSVLink } from "react-csv";

export const FoundationPackTable = ({ columns, data }) => {
    const csvLinkRef = useRef(null);
    const tableInstance = useTable({
        columns,
        data,
        // defaultColumn,
        // filterTypes,
        initialState: { pageIndex: 0, pageSize: 100 }
    },
        useFilters,
        useGlobalFilter,
        usePagination
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance

    const headers = [
        { label: "Email", key: "Email" },
        { label: "Team Email", key: "TeamEmail" },
        { label: "Amount Paid", key: "AmountPaid" },
        { label: "Counpon Code", key: "CouponCode" },
        { label: "Pack Status", key: "PackStatus" },
        { label: "Payment Date", key: "PaymentDate" },
    ];
    const downloadReport = async () => {
        csvLinkRef.current.link.click()
    }
    return (
        <React.Fragment>
            <div className="container">
                <div className="row mb-3">
                    <div className="col-md-6 listing-button">
                        <button type="button" className="btn btn-secondary" onClick={() => { downloadReport() }}>
                            Export to CSV
                            <CSVLink
                                headers={headers}
                                filename="foundationPack.csv"
                                data={data}
                                ref={csvLinkRef}
                            />
                        </button>
                        {/* {user.role === "admin" | user.role === "istAdmin" || user.role === "bdAdmin" ?
              <Link to="/users" className="btn btn-secondary" style={{ marginLeft: "12px" }}>
                Users
              </Link>
              :
              ""
            } */}
                    </div>
                    <div className="col-md-2 offset-md-4">
                        <input type="text" className='form-control' name="gloablaSearch" placeholder='Search' value={state.globalFilter || ''} onChange={e => {
                            const val = e.target.value
                            setGlobalFilter(val)
                        }} />
                    </div>
                </div>

                <table {...getTableProps()} style={{ border: 'solid 1px blue', width: "100%" }}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                            borderBottom: 'solid 3px red',
                                            background: 'aliceblue',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {column.render('Header')}
                                        {/* Render the columns filter UI */}
                                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                style={{
                                                    padding: '10px',
                                                    border: 'solid 1px gray',
                                                    background: 'papayawhip',
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        {footerGroups.map(group => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[100, 200, 300, 400, 500].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </React.Fragment>
    )
}