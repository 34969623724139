import React, { useEffect, useState } from 'react'
import { fetchFoundationPackUsers } from './financepeer.services';
import { loggedInUser } from '../../utils/auth';
import { Navbar } from '../../components/Navbar';
import { Toastify } from '../../components/toaster/Toastify';
import { RotatingLinesLoader } from '../../components/loader/RotatingLinesLoader';
import { FoundationPackTable } from './FoundationPackTable';
import styled from 'styled-components'

export const FoundationPackUser = () => {
    const [loading, setLoading] = useState(false);
    const [packUsers, setPackUsers] = useState([])
    useEffect(() => {
        fetchFoundationPack()
    }, []);
    const fetchFoundationPack = async () => {
        let user = await loggedInUser()
        let packUsers = await fetchFoundationPackUsers({ teamType: user.role })
        setLoading(true);
        if (packUsers.status) {
            setPackUsers(packUsers.response);
            setLoading(false);
        } else {
            setLoading(false);
            Toastify('error', 'Something went wrong')
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'Email', // accessor is the "key" in the data
                Footer: "Email",
                disableFilters: true
            },
            {
              Header:"Team Email",
              accessor:"TeamEmail",
              Footer:"Team Email",
              disableFilters: true
            },
            {
                Header: 'Amount Paid',
                accessor: 'AmountPaid', // accessor is the "key" in the data
                Footer: "Amount Paid",
                disableFilters: true
            },
            {
                Header: 'Coupon Code',
                accessor: 'CouponCode',
                Footer: "Coupon Code",
                disableFilters: true
            },
            {
                Header: 'Pack Status',
                accessor: 'PackStatus',
                Footer: "Pack Status",
                Cell: ({ row: { original } }) => (
                    original.PackStatus === "NOT ACTIVATED" ?
                        <>
                            <span class="badge bg-warning text-dark">{original.PackStatus}</span>
                        </>
                        :
                        original.PackStatus === "PACK ACTIVATED" ?
                            <>
                                <span class="badge bg-success">{original.PackStatus}</span>
                            </>
                            :
                            original.PackStatus === "EXPIRED" ?
                                <>
                                    <span class="badge bg-danger">{original.PackStatus}</span>
                                </>
                                :
                                <>
                                    <span class="badge bg-info text-dark">{original.packStatus}</span>
                                </>
                )

            },
            {
                Header: 'Payment Date',
                accessor: 'PaymentDate',
                Footer: "Payment Date",
                disableFilters: true
            },
        ],
        []
    )
    const Styles = styled.div`
      padding: 1rem;
    
      table {
        border-spacing: 0;
        border: 1px solid black;
    
        tr {
          ${'' /* :first-child {
            td {
              border-top: 1px solid black;
            }
          } */}
    
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }
    
        th,
        td {
          margin: 0;
          padding: 0.5rem;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
    
          :last-child {
            border-right: 0;
          }
        }
    
        tfoot {
          tr:first-child {
            td {
              border-top: 2px solid black;
            }
          }
          font-weight: bolder;
        }
      }
      .pagination button  {
        margin: 0px 3px;
      }
      .pagination {
        padding: 0.5rem;
      }
    `

    return (
        <React.Fragment>
            <div className='foundation-packs'>
                <Navbar />
                {loading ?
                    <RotatingLinesLoader />
                    :
                    <>
                        {packUsers.length > 0 ?
                            <Styles>
                                <FoundationPackTable columns={columns} data={packUsers} />
                            </Styles>
                            :
                            <div className="nodatafound" style={{ textAlign: "center", marginTop: "10px" }}>
                                <h2>No data found</h2>
                            </div>
                        }
                    </>
                }
            </div>
        </React.Fragment>
    )
}
