import React from 'react'
import moment from "moment";
import { Example } from './Example'
import styled from 'styled-components'


export const Example1 = () => {
    const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      ${'' /* :first-child {
        td {
          border-top: 1px solid black;
        }
      } */}

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    tfoot {
      tr:first-child {
        td {
          border-top: 2px solid black;
        }
      }
      font-weight: bolder;
    }
  }
  .pagination button  {
    margin: 0px 3px;
  }
  .pagination {
    padding: 0.5rem;
  }
`
let data = [
  { firstName: "Satish", lastName: "Palsra", email: "s@gmail.com", age: 28, status: "Inprogress", date: "2023-01-21" },
  { firstName: "Sachin", lastName: "Pal", email: "sachin@gmail.com", age: 22, status: "Pending", date: "2023-01-10" },
  { firstName: "Ankush", lastName: "Thakur", email: "ankush@gmail.com", age: 25, status: "Complete", date: "2023-01-06" },
  { firstName: "Kuldeep", lastName: "Jha", email: "kuldeep@gmail.com", age: 26, status: "Complete", date: "2023-02-07" },
  { firstName: "Akshay", lastName: "Kumar", email: "akshay@gmail.com", age: 29, status: "Inprogress", date: "2023-02-10" },
  { firstName: "Randhir", lastName: "Singh", email: "rnadhir@gmail.com", age: 30, status: "Pending", date: "2023-02-15" },
  { firstName: "Jagdish", lastName: "Bhaghuni", email: "jagdish@gmail.com", age: 31, status: "Complete", date: "2023-02-21" }
]
    
    const DateRangeColumnFilter = ({ column: { filterValue = [], preFilteredRows, setFilter, id },}) => {
      // const [min, max] = React.useMemo(() => {
      //   console.log("DateRangeColumnFilter @@@@@@@@@" ,preFilteredRows)
      //   let min = moment(preFilteredRows[0].values[id])
      //   let max = moment(preFilteredRows[0].values[id])
      //   // 
      //   preFilteredRows.forEach(row => {
      //     min = moment.min(moment(row.values[id]), min)
      //     max = moment.max(moment(row.values[id]), max)
      //   });
      //   return [min, max];
      // }, [id, preFilteredRows])
      return (
        <div
          style={{
            display: 'flex',
          }}
        >
          <input
            value={filterValue[0] || ''}
            type="date"
            // id="startDate"
            onChange={e => {
              const val = e.target.value
              setFilter((old = []) => [val ? (val) : undefined, old[1]])
            }}
            // placeholder={`Min (${min})`}
            style={{
              width: '50%',
              marginRight: '0.5rem',
            }}
          />
          to
          <input
            value={filterValue[1] || ''}
            type="date"
            // id="endDate"
            onChange={e => {
              const val = e.target.value
              setFilter((old = []) => [old[0], val ? (val) : undefined])
            }}
            // placeholder={`Max (${max})`}
            style={{
              width: '50%',
              marginLeft: '0.5rem',
            }}
          />
        </div>
      )
    }
    const SelectColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter, id },}) => {
      console.log("preFilteredRows" ,preFilteredRows)
      const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
          options.add(row.values[id])
        })
        return [...options.values()]
      }, [id, preFilteredRows])
      console.log("preFilteredRows" ,options)
    }
    const columns = React.useMemo(
        () => [
            // {
            //     Header: "Name",
            //     Footer: "Name",
            //     columns: [
            //         {
            //             Header: 'FirstName',
            //             accessor: 'firstName', // accessor is the "key" in the data
            //             Footer: "FirstName"
            //         },
            //         {
            //             Header: 'LastName',
            //             accessor: 'lastName',
            //             Footer: "LastName"
            //         },
            //     ]
            // },
            {
                Header: 'FirstName',
                accessor: 'firstName', // accessor is the "key" in the data
                Footer: "FirstName",
                disableFilters:true
            },
            {
                Header: 'LastName',
                accessor: 'lastName',
                Footer: "LastName",
                disableFilters:true
            },
            {
                Header: 'Email',
                accessor: 'email', // accessor is the "key" in the data
                Footer: "Email",
                disableFilters:true
            },
            {
                Header: 'Age',
                accessor: 'age',
                Footer: "Age",
                disableFilters:true
            },
            {
              Header: 'Status',
              accessor: 'status',
              Footer: "Status",
              Filter: SelectColumnFilter
          },
            {
              Header:"Date",
              accessor:'date',
              Filter: DateRangeColumnFilter,
              filter: "dateBetween",
              Footer: "Date"
            }
        ],
        []
    )
    return (
        <React.Fragment>
            <Styles>
                <Example columns={columns} data={data} />
            </Styles>
        </React.Fragment>
    )
}
