import React from 'react'
import './pageNotFound.styles.css'

export const PageNotFound = () => {
    return (
        <div id="pagenotfound">
            <div className="fof">
                <h2>Oops!</h2>
                <h1>Error 404 Not Found</h1>
                <p>Sorry, an error has occured, Requested page not found!</p>
            </div>
        </div>
    )
}
