import React from 'react'
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table'
import moment from "moment";

export const Example = ({ columns, data }) => {
     
    const dateBetweenFilterFn = (rows, id, filterValues) => {
        console.log("DateRangeColumnFilter ?>>>>>>>" ,filterValues)
        let sd = moment(filterValues[0]).format('YYYY-MM-DD');
        let ed = moment(filterValues[1]).format('YYYY-MM-DD');
        return rows.filter(r => {
          var time = moment(r.values[id]).format('YYYY-MM-DD');
          if (filterValues[0] === undefined && filterValues[1] === undefined) {
            return rows
          } else if (time >= sd && time <= ed) {
            return r
          } 
    
        });
    }
    dateBetweenFilterFn.autoRemove = val => !val;

    const filterTypes = React.useMemo(() => ({
        dateBetween: dateBetweenFilterFn, 
    }),[])

    const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter }, }) => {
        const count = preFilteredRows.length
        return (
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const tableInstance = useTable({
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 4 }
    },
        useFilters,
        useGlobalFilter,
        usePagination
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance
    return (
        <React.Fragment>
            <div className="container">
                <div className="row mb-3">
                    <div>Example Table</div>
                    <div className="col-md-2 offset-md-10">
                        <input type="text" className='form-control' name="gloablaSearch" placeholder='Search' value={state.globalFilter || ''} onChange={e => {
                            const val = e.target.value
                            setGlobalFilter(val)
                        }} />
                    </div>
                </div>

                <table {...getTableProps()} style={{ border: 'solid 1px blue', width: "100%" }}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                            borderBottom: 'solid 3px red',
                                            background: 'aliceblue',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {column.render('Header')}
                                        {/* Render the columns filter UI */}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                style={{
                                                    padding: '10px',
                                                    border: 'solid 1px gray',
                                                    background: 'papayawhip',
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        {footerGroups.map(group => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </React.Fragment>
    )
}
