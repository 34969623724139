import React from 'react'
import {
  createBrowserRouter
} from "react-router-dom";

import Financepeer from '../features/financepeer/Financepeer';
import { CreateLoanForm } from '../features/financepeer/CreateLoanForm';
import { Login } from '../features/auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
// import { PublicRoute } from './PublicRoute';
import { PageNotFound } from '../components/404/PageNotFound';
import { GeneratePassword } from '../features/generatePassword/GeneratePassword';
import { Users } from '../features/generatePassword/Users';
import { UpdateUser } from '../features/generatePassword/UpdateUser';
import { FoundationPackUserLogin } from '../features/auth/FoundationPackUserLogin';
import { FoundationPackUsers } from '../features/foundationPackUsers/FoundationPackUsers';
import { CreateFoundationPackUser } from '../features/foundationPackUsers/CreateFoundationPackUser';
import { Example } from '../features/foundationPackUsers/Example';
import { Example1 } from '../features/foundationPackUsers/Example1';
import { ProtectedFoundationRoute } from './ProtectedFoundationRoute';
import { FoundationPackUser } from '../features/financepeer/FoundationPackUser';





export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/create-loan",
    element: <ProtectedRoute><CreateLoanForm /></ProtectedRoute>
  },
  {
    path: "/listing",
    element: <ProtectedRoute><Financepeer /></ProtectedRoute>
  },
  {
    path: "/generate-password",
    element: <ProtectedRoute><GeneratePassword /></ProtectedRoute>
  },
  {
    path: "/users",
    element: <ProtectedRoute> <Users /></ProtectedRoute>
  },
  {
    path: "/update-user/:id",
    element: <ProtectedRoute><UpdateUser /></ProtectedRoute>
  },
  {
     path:"/foundation-packs",
     element: <ProtectedRoute><FoundationPackUser /></ProtectedRoute>
  },
  //foundation pack users
  {
    path: "/login/:teamType",
    element: <FoundationPackUserLogin />
  },
  {
    path: "/create-foundation-pack-users/:teamType",
    element: <ProtectedFoundationRoute><CreateFoundationPackUser /></ProtectedFoundationRoute>
  },
  {
    path: "/foundation-pack-users/:teamType",
    element: <ProtectedFoundationRoute><FoundationPackUsers /></ProtectedFoundationRoute>
  },
  {
    path: "/demo",
    element: <Example1 />
  },
  {
    path: "*",
    element: <PageNotFound />
  }

]);



