import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth";

export const ProtectedRoute = ({ children }) => {
  let isAuth = isAuthenticated();
  return isAuth ? children : <Navigate to="/login" />;;
}

// const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
//     return isAuthenticated ? children : <Navigate to="/login" />;
// };

// export const  PrivateRoute = ({ children })  => {
//   // const auth = useAuth();
//   return auth ? children : <Navigate to="/login" />;
// }

