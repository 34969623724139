const bcrypt = require('bcryptjs');
const CryptoJS = require("crypto-js");

export const generateHashPassword = async (password) => {
    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(password, salt);
    return hash;
}

export const compareHashPassword = async (password, hashPassword) => {
    let comparePassword = await bcrypt.compare(password, hashPassword);
    return comparePassword;
}

export const encryption = async (data) => {
    let secretKey =  process.env.REACT_APP_CRYPTO_SECRETKEY
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
}

export const decryption = async (ciphertext) => {
    let secretKey = process.env.REACT_APP_CRYPTO_SECRETKEY
    let bytes  = CryptoJS.AES.decrypt(ciphertext, secretKey);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData;
}