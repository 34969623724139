import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Toastify } from './toaster/Toastify';
import { loggedInUser } from '../utils/auth';

export const Navbar = () => {
    let navigate = useNavigate();
    const [user, setUser] = useState({})
    useEffect(() => {
        loggedUser()
    }, []);
    const loggedUser = async () => {
        let user = await loggedInUser()
        setUser(user)
    }
    const logout = () => {
        localStorage.removeItem('prepfinancepeer')
        Toastify("success", 'Logout successfully')
        navigate("/login")
    }
    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand">Prepladder</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="void(0)">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="void(0)">Link</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="void(0)" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="void(0)">Action</a></li>
                                    <li><a className="dropdown-item" href="void(0)">Another action</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="void(0)">Something else here</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" href="void(0)" tabindex="-1" aria-disabled="true">Disabled</a>
                            </li> */}
                        </ul>
                        <form className="d-flex">
                            {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success" type="submit">Search</button> */}
                            {/* <a className="dropdown-item" href="void(0)">Home</a> */}
                            {user.role === "admin" | user.role === "istAdmin" || user.role === "bdAdmin" ?
                                <>
                                    <Link className="dropdown-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Foundation packs" to='/foundation-packs'>Foundation pack</Link>
                                    <Link className="dropdown-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Users" to='/users'>Users</Link>
                                </>
                                :
                                ""
                            }
                            <Link className="dropdown-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Create loan request" to='/create-loan'>Loan Request</Link>
                            <Link className="dropdown-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Listing" to='/listing'>Listing</Link>
                            <button className="dropdown-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Logout" onClick={() => { logout() }}> <i className="fa-solid fa-right-from-bracket"></i></button>
                            {/* <a className="dropdown-item" href="void(0)">Listing</a>   */}
                        </form>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}
