import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { createPackUsersSchema } from '../../validations/createPackUsersValidation';
import { createFoundationPackUser } from '../../features/foundationPackUsers/foundationPackServices';
import { foundationLoggedInUser, isFoundationAuthenticated } from '../../utils/foundationPackAuth';
import { useNavigate } from "react-router-dom";


export const CreateFoundationPackUsers = ({ show, handleClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState({});
    useEffect(() => {
        fetchloggedIUser()
    }, []);
    const fetchloggedIUser = async () => {
        let auth = await isFoundationAuthenticated()
        if (auth) {
            let user = await foundationLoggedInUser();
            setLoggedInUser(user)
        } else {

        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create Foundation Pack
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            email: '',
                            // password: '',
                            // role: ''
                        }}
                        validationSchema={createPackUsersSchema}
                        onSubmit={async (values, { resetForm }) => {
                            values['teamID'] = loggedInUser.id
                            values['teamType'] = loggedInUser.role
                            values['teamEmail'] = loggedInUser.email
                            setLoading(true)
                            let createFoundation = await createFoundationPackUser(values);
                            handleClose();
                            console.log("loggedInUser ??????????", createFoundation)
                        }}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleChange} value={values['email']} />
                                    {touched.email && errors.email ? (
                                        <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="col-12">
                                    <Button variant="secondary" type="submit">
                                        Submit
                                    </Button>
                                    {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}