import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { confirmable } from 'react-confirm';

const ConfirmPopup = ({ show, proceed, confirmation, options, title = "Confirmation", okLabel = "OK",
    cancelLabel = "Cancel", ...rest}) => {
    return (
        <>
            <Modal
                animation={true}
                show={show}
                onHide={() => proceed(false)}
            // backdrop={enableEscape ? true : "static"}
            // keyboard={enableEscape}
            >
                <Modal.Header>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{confirmation}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => proceed(false)}>
                        {cancelLabel}
                    </Button>
                    <Button
                        className="button-l"
                        // bsStyle="primary"
                        onClick={() => proceed(true)}
                    >
                        {okLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default confirmable(ConfirmPopup);

