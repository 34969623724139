

export const isAuthenticated = () => {
    let user = localStorage.getItem("prepfinancepeer");
    if (user) {
        return true
    } else {
        return false
    }
}

export const loggedInUser = () => {
    let user = localStorage.getItem("prepfinancepeer");
    return JSON.parse(user);
}

export const logout = () => {
    localStorage.removeItem('prepfinancepeer')
}