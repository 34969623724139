import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import { confirm } from '../../components/confirmBox/ConfirmFunction';
import { generatePassword } from './generate.services';
import { signInSchema } from '../../validations/loginValidation';
import { Toastify } from '../../components/toaster/Toastify';
import { loggedInUser, isAuthenticated } from '../../utils/auth'
import { useNavigate } from "react-router-dom";
import { LightSpinner } from '../../components/loader/LightSpinner';
import { Navbar } from '../../components/Navbar';


export const GeneratePassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loggedUser, setLoggedInUser] = useState({})
    useEffect(() => {
        loggedInuser()
    }, [])
    const loggedInuser = async () => {
        let isAuth = await isAuthenticated()
        let user = await loggedInUser();
        if (isAuth && user['role'] !== 'ist' && user['role'] !== 'bd') {
            setLoggedInUser(user)
            navigate("/generate-password");
        } else {
            navigate("/listing");
        }
    }
    return (
        <React.Fragment>
            <div className="generate-password">
            <Navbar/>
                {/* {loading ? <InfinitySpinner /> */}
                {/* : */}
                <div className="global-container">
                    <div className="card login-form">
                        <div className="card-body">
                            <h3 className="card-title text-center">Create user</h3>
                            <div className="card-text">
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                        role: ''
                                    }}
                                    validationSchema={signInSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        if (await confirm({ confirmation: 'Are you sure to submit this application ?' })) {
                                            setLoading(true);
                                            let generate = await generatePassword(values)
                                            if (generate.status) {
                                                setLoading(false);
                                                Toastify("success", "Password generate successfully")
                                                resetForm()
                                                navigate('/users')
                                            } else {
                                                setLoading(false);
                                                Toastify("error", "Something went wrong")
                                            }
                                        } else {
                                            return;
                                        }
                                    }}
                                >
                                    {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label for="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleChange} value={values['email']} />
                                                {touched.email && errors.email ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label for="password" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="password" placeholder="Password" name="password" onChange={handleChange} value={values['password']} />
                                                {touched.password && errors.password ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.password}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label for="role" className="form-label">Role</label>
                                                <select className="form-select" aria-label="select" name="role" onChange={handleChange} value={values['role']}>
                                                    <option >Select role</option>
                                                    {/* <option value="ist">IST</option>
                                                    <option value="bd">BD</option>
                                                    <option value="admin">ADMIN</option>
                                                    <option value="istAdmin">IST - ADMIN</option>
                                                    <option value="bdAdmin">BD - ADMIN</option> */}
                                                     {loggedUser.role === "istAdmin" ?
                                                        <option value="ist">IST</option>
                                                        :
                                                        <>
                                                            {loggedUser.role === "bdAdmin" ?
                                                                <option value="bd">BD</option>
                                                                :
                                                                <>
                                                                    {loggedUser.role === "admin" ?
                                                                        <>
                                                                            <option value="ist">IST</option>
                                                                            <option value="bd">BD</option>
                                                                            <option value="admin">ADMIN</option>
                                                                            <option value="istAdmin">IST - ADMIN</option>
                                                                            <option value="bdAdmin">BD - ADMIN</option>
                                                                        </>
                                                                        :
                                                                        ""
                                                                    }
                                                                </>

                                                            }

                                                        </>
                                                    }
                                                </select>
                                                {touched.role && errors.role ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.role}</div>
                                                ) : null}
                                            </div>
                                            {/* <div class="form-check">
                           <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                           <label class="form-check-label" for="flexCheckDefault">
                               Remember me
                           </label>
                       </div> */}
                                            <div className="mb-3 d-grid">
                                                {loading ?
                                                    <button className="btn" type="button">
                                                        <LightSpinner />
                                                    </button>
                                                    :
                                                    <button className="btn" type="submit">Submit</button>
                                                }
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </div>
        </React.Fragment>
    )
}
