import React, { useEffect, useState } from 'react'
import { LightSpinner } from '../../components/loader/LightSpinner';
import { Formik } from 'formik';
import { confirm } from '../../components/confirmBox/ConfirmFunction';
import { Toastify } from '../../components/toaster/Toastify';
import { fetchUser, updateUser } from './generate.services';
import { useParams } from "react-router-dom"
import { loggedInUser } from '../../utils/auth';
import { useNavigate } from "react-router-dom";
import { updateUserSchema } from '../../validations/updateUserValidation';
import { Navbar } from '../../components/Navbar';
import { InfinitySpinner } from '../../components/loader/InfinitySpinner';


export const UpdateUser = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [IsUpdateLoading, setIsUpdateLoading] = useState(false);
    const [loggedUser, setLoggedInUser] = useState({})
    useEffect(() => {
        isAuth()
    }, [])
    useEffect(() => {
        fetch();
        fetchLoggedInUser()
    }, [])
    const fetch = async () => {
        setLoading(true)
        let fetchData = await fetchUser({ id: params.id })
        setData(fetchData.response[0])
        setLoading(false)
    }
    const fetchLoggedInUser = async () => {
        let user = await loggedInUser()
        setLoggedInUser(user)
    }
    const isAuth = async () => {
        let user = await loggedInUser()
        if (user.role === "bd" || user.role === "ist") {
            navigate('/listing')
        }
    }
    return (
        <React.Fragment>
            <div className="update-user">
                <Navbar />
                {loading ? <InfinitySpinner />
                    :
                    <div className="global-container">
                        <div className="card login-form">
                            <div className="card-body">
                                <h3 className="card-title text-center">Update user</h3>
                                <div className="card-text">
                                    <Formik
                                        initialValues={{
                                            email: data['email'] ? data['email'] : '',
                                            password: '',
                                            role: data['role'] ? data['role'] : ''
                                        }}
                                        enableReinitialize
                                        validationSchema={updateUserSchema}
                                        onSubmit={async (values, { resetForm }) => {
                                            let body = {}
                                            body['id'] = params.id
                                            body['email'] = values['email']
                                            body['role'] = values['role']
                                            if (values['password']) {
                                                body['password'] = values['password']
                                            } else {
                                                body['password'] = ""
                                            }
                                            if (await confirm({ confirmation: 'Are you sure to update this application ?' })) {
                                                setIsUpdateLoading(true);
                                                let update = await updateUser(body)
                                                if (update.status) {
                                                    setIsUpdateLoading(false);
                                                    Toastify("success", "Updated successfully")
                                                    resetForm()
                                                    navigate('/users')
                                                } else {
                                                    setIsUpdateLoading(false);
                                                    Toastify("error", "Something went wrong")
                                                }
                                            } else {
                                                return;
                                            }
                                        }}
                                    >
                                        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label for="email" className="form-label">Email</label>
                                                    <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleChange} value={values['email']} readOnly />
                                                    {touched.email && errors.email ? (
                                                        <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label for="password" className="form-label">Password</label>
                                                    <input type="password" className="form-control" id="password" placeholder="Password" name="password" onChange={handleChange} value={values['password']} />
                                                    {touched.password && errors.password ? (
                                                        <div className="form-text" style={{ color: "red" }}>{errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label for="role" className="form-label">Role</label>
                                                    <select className="form-select" aria-label="select" name="role" onChange={handleChange} value={values['role']}>
                                                        {/* <option >Select role</option> */}
                                                        {loggedUser.role === "istAdmin" ?
                                                            <option value="ist">IST</option>
                                                            :
                                                            <>
                                                                {loggedUser.role === "bdAdmin" ?
                                                                    <option value="bd">BD</option>
                                                                    :
                                                                    <>
                                                                        {loggedUser.role === "admin" ?
                                                                            <>
                                                                                <option value="ist">IST</option>
                                                                                <option value="bd">BD</option>
                                                                                <option value="admin">ADMIN</option>
                                                                                <option value="istAdmin">IST - ADMIN</option>
                                                                                <option value="bdAdmin">BD - ADMIN</option>
                                                                            </>
                                                                            :
                                                                            ""
                                                                        }
                                                                    </>

                                                                }

                                                            </>
                                                        }
                                                    </select>
                                                    {touched.role && errors.role ? (
                                                        <div className="form-text" style={{ color: "red" }}>{errors.role}</div>
                                                    ) : null}
                                                </div>
                                                {/* <div class="form-check">
                           <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                           <label class="form-check-label" for="flexCheckDefault">
                               Remember me
                           </label>
                       </div> */}
                                                <div className="mb-3 d-grid">
                                                    {IsUpdateLoading ?
                                                        <button className="btn" type="button">
                                                            <LightSpinner />
                                                        </button>
                                                        :
                                                        <button className="btn" type="submit">Update</button>
                                                    }
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
