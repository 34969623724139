import React, { useEffect, useState } from 'react'
import { fetchUsers } from './generate.services';
import { loggedInUser } from '../../utils/auth';
import { useNavigate, Link } from "react-router-dom";
import { Navbar } from '../../components/Navbar';
import { InfinitySpinner } from '../../components/loader/InfinitySpinner';

export const Users = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        isAuth()
    }, [])
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        setLoading(true);
        let user = await loggedInUser()
        let result = await fetchUsers({ role: user.role });
        setData(result.response)
        setLoading(false);
    }
    const isAuth = async () => {
        let user = await loggedInUser()
        if (user.role === "bd" || user.role === "ist") {
            navigate('/listing')
        }
    }
    return (
        <React.Fragment>
            <Navbar />
            <div className='container'>
                <div className='users' style={{ marginBottom: "15px" }}>
                    <div className='row'>
                        <div className='col-md-12' style={{ marginTop: "15px" }}>
                            <Link to="/generate-password" className="btn btn-secondary">
                                Create user
                            </Link>
                        </div>
                    </div>
                </div>
                {loading ? <InfinitySpinner />
                    :
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Email</th>
                                {/* <th scope="col">Role</th> */}
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((result) => {
                                return (
                                    <tr key={result.id}>
                                        <th scope="row">{result.id}</th>
                                        <td>{result.email}</td>
                                        {/* <td>{result.role}</td> */}
                                        <td><Link to={`/update-user/${result.id}`} class="btn btn-info">Update</Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </React.Fragment>
    )
}
