import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import { signInSchema } from '../../validations/loginValidation';
import { useNavigate } from "react-router-dom";
import { Toastify } from '../../components/toaster/Toastify';
import { compareHashPassword } from '../../utils/bycrypt.helper';
import { login } from '../financepeer/financepeer.services';
import { isAuthenticated } from '../../utils/auth';
import { InfinitySpinner } from '../../components/loader/InfinitySpinner';
import { LightSpinner } from '../../components/loader/LightSpinner';
import './auth.style.css'


export const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        isAuth()
    }, [])
    const isAuth = async () => {
        const isAuthicate = await isAuthenticated();
        if (isAuthicate) {
            navigate("/listing")
        } else {
            navigate('/login')
        }
    }
    return (
        <React.Fragment>
            <div className="pt-5">
                {/* {loading ? <InfinitySpinner />
                    : */}
                <div className="global-container">
                    <div className="card login-form">
                        <div className="card-body">
                            <h3 className="card-title text-center">  Login </h3>
                            <div className="card-text">
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                        role: ''
                                    }}
                                    validationSchema={signInSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        setLoading(true)
                                        let auth = await login(values);
                                        let user;
                                        if (auth.status) {
                                            setLoading(false)
                                            user = auth.response
                                            if (user.length > 0) {
                                                if (user[0].role === 'ist') {
                                                    Toastify('success', 'Login successfully')
                                                    delete user[0].password
                                                    // delete user[0].hasPassword
                                                    localStorage.setItem("prepfinancepeer", JSON.stringify(user[0]));
                                                    navigate("/listing");
                                                }
                                                if (user[0].role === 'bd') {
                                                    Toastify('success', 'Login successfully')
                                                    delete user[0].password
                                                    // delete user[0].hasPassword
                                                    localStorage.setItem("prepfinancepeer", JSON.stringify(user[0]));
                                                    navigate("/listing");
                                                }
                                                if (user[0].role === 'istAdmin') {
                                                    Toastify('success', 'Login successfully')
                                                    delete user[0].password
                                                    // delete user[0].hasPassword
                                                    localStorage.setItem("prepfinancepeer", JSON.stringify(user[0]));
                                                    navigate("/listing");
                                                }
                                                if (user[0].role === 'bdAdmin') {
                                                    Toastify('success', 'Login successfully')
                                                    delete user[0].password
                                                    // delete user[0].hasPassword
                                                    localStorage.setItem("prepfinancepeer", JSON.stringify(user[0]));
                                                    navigate("/listing");
                                                }
                                                if (user[0].role === 'admin') {
                                                    Toastify('success', 'Login successfully')
                                                    delete user[0].password
                                                    // delete user[0].hasPassword
                                                    localStorage.setItem("prepfinancepeer", JSON.stringify(user[0]));
                                                    navigate("/listing");
                                                }
                                            } else {
                                                Toastify('error', 'Invalid credentials')
                                                return false;
                                            }
                                        } else {
                                            setLoading(false)
                                            Toastify('error', auth.message)
                                        }

                                    }}
                                >
                                    {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label for="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleChange} value={values['email']} />
                                                {touched.email && errors.email ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label for="password" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="password" placeholder="Password" name="password" onChange={handleChange} value={values['password']} />
                                                {touched.password && errors.password ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.password}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label for="role" className="form-label">Role</label>
                                                <select className="form-select" aria-label="select" name="role" onChange={handleChange} value={values['role']}>
                                                    <option >Select role</option>
                                                    <option value="ist">IST</option>
                                                    <option value="bd">BD</option>
                                                    <option value="admin">ADMIN</option>
                                                    <option value="istAdmin">IST - ADMIN</option>
                                                    <option value="bdAdmin">BD - ADMIN</option>
                                                </select>
                                                {touched.role && errors.role ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.role}</div>
                                                ) : null}
                                            </div>
                                            {/* <div class="form-check">
                                   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                   <label class="form-check-label" for="flexCheckDefault">
                                       Remember me
                                   </label>
                               </div> */}
                                            <div className="mb-3 d-grid">
                                                {loading ?
                                                    <button className="btn" type="button">
                                                        <LightSpinner/>
                                                    </button>
                                                    :
                                                    <button className="btn" type="submit">Login</button>
                                                }

                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </div>
        </React.Fragment>
    )
}
