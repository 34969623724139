import * as Yup from 'yup';

export const financePeerSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first Name'),
    lastName: Yup.string().required('Please enter your last Name'),
    gender: Yup.string().required('Please enter your gender'), 
    city: Yup.string().optional().max(32, "City name must be less than 32 characters long"),
    state: Yup.string().required("Please enter your state"), 
    country: Yup.string().optional(),
    addressLine1: Yup.string().optional().min(5, "Address Line 1 must be more than 5 characters long").max(255, "Address Line 1 must be less than 255 characters long"),
    addressLine2: Yup.string().optional().min(5, "Address Line 2 must be more than 5 characters long").max(255, "Address Line 2 must be less than 255 characters long"),
    pincode: Yup.string().optional(),
    course: Yup.string().required('Please enter a course'),
    subject: Yup.string().required('Please enter a pack'),
    appicantMobile: Yup.string().required('Please enter your mobile').min(10, "Phone number must be more than 10 characters long").max(20, "Phone number must be less than 20 characters long"),
    email: Yup.string().email('Please enter a valid email').required('Please enter your applicant email'),
    studentEmail: Yup.string().email('Please enter a valid email').required('Please enter your student email'),
    pan: Yup.string().required('Please enter your pan'),
    tenure: Yup.number().typeError("Tenure must be a number").required('Please enter your tenure'),
    studentDob: Yup.string().nullable().required('Please enter your dob'),
    applicantDob: Yup.string().nullable().required('Please enter your dob'),
    // fee: Yup.number().typeError("Fee must be a number").required('Please enter your fee').positive().integer(),
    leadName: Yup.string().optional(),
    teamName: Yup.string().optional(),
    relationship:Yup.string().required('Please enter your Relationship')
});
