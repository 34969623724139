import React from 'react'
import { InfinitySpin } from 'react-loader-spinner'

export const InfinitySpinner = () => {
  return (
    <div className="infinite-spinner" style={{ textAlign: 'center' }}>
      <InfinitySpin
        width='200'
        color="#808080"
      />
    </div>
  )
}
