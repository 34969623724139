import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

export const RotatingLinesLoader = () => {
    return (
        <div className="rotating-lines" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh'
        }}>
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        </div>
    )
}