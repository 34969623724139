import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import { financePeerSchema } from '../../validations/financePeerValidation';
import { createLoanApplication } from './financepeer.services';
import DatePicker from 'react-date-picker';
import { Navbar } from '../../components/Navbar';
import { fetchCourses, fetchCoursePlans, PackageWebUIController, fetchCoursePlanName, fetchStates } from './financepeer.services';
import { Toastify } from '../../components/toaster/Toastify';
import { useNavigate } from "react-router-dom";
import { ColorRingLoader } from '../../components/loader/ColorRingLoader';
import { ErrorPopup } from '../../components/modal/ErrorPopup';
import { confirm } from '../../components/confirmBox/ConfirmFunction';
import { RotatingLinesLoader } from '../../components/loader/RotatingLinesLoader';
import { loggedInUser } from '../../utils/auth';

export const CreateLoanForm = () => {
  const [courses, setCourses] = useState([])
  const [packs, setPacks] = useState([])
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    applicantDob: '',
    appicantMobile: '',
    pan: '',
    email: '',
    studentEmail: '',
    couponCode: 'DREAMTEAM',
    studentDob: '',
    relationship: '',
    tenure: ''
  });
  const [finalAmountInr, setFinalAmountInr] = useState('Please choose pack')
  const [isPackLoading, setPackLoading] = useState(false)
  const [isPriceLoading, setPriceLoading] = useState(false)
  const [isCreateLoanLoading, setCreateLoanLoading] = useState(false)
  const [isFieldError, setIsFieldError] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])
  const [samAs, setSameAs] = useState(false);
  const [states, setStates] = useState([])
  const [role ,setRole] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getCourses();
    getStates();
    getLoggedInUser();
  }, [finalAmountInr])

  const getCourses = async () => {
    let course = await fetchCourses()
    setCourses(course.response);
  }
  const getStates = async () => {
    let states = await fetchStates()
    setStates(states.response)
  }
  const getLoggedInUser = async () => {
     let user = await loggedInUser()
     setRole(user)
  }
  // let user = localStorage.getItem('prepfinancepeer')
  // let role = JSON.parse(user);
  const courseHandelChange = async (e) => {
    let errorMessage = []
    Object.keys(state).forEach(key => {
      if (state[key] === undefined || state[key] === null || state[key] === '') {
        let error = {}
        error[key] = `Please fill ${key}`
        errorMessage.push(error)
        setPacks([])
      }
    });
    if (errorMessage.length > 0) {
      setErrorMessage(errorMessage)
      setIsFieldError(true)
      return;
    }
    let value = e.target.value
    if (value) {
      let course = courses.filter(course => course.id === parseInt(value))
      if (course.length > 0) {
        let body = {
          courseID: course[0]['id'],
          packID: course[0]['packID'],
          email: state['email'],
          coupon: state['couponCode'],
          phone: state['appicantMobile']
        }
        setPackLoading(true)
        let fetchCoursePlan = await fetchCoursePlans(body);
        if (fetchCoursePlan.status) {
          setPacks(fetchCoursePlan.response.plans);
          setPackLoading(false)
        }
        setFinalAmountInr("Please choose pack")
      }
    } else {
      setPacks([])
      setFinalAmountInr('Please select course')
    }
  }
  const packHandelChange = async (e) => {
    let { value } = e.target
    //get plan name
    let planName = await fetchCoursePlanName({ subscriptionID: value })
    if (planName['status']) {
      state['planName'] = planName.response[0].planName
    }
    if (value) {
      //check existing pack
      let pack = packs.filter(pack => pack.subscriptionID === parseInt(value))
      if (pack.length > 0) {
        if (pack[0].timePeriod >= state['tenure']) {
          state['planName'] = pack[0].planName
          state['packID'] = pack[0].packID
          state['defaultValidity'] = pack[0].timePeriod
          state['planID'] = pack[0].subscriptionID
          state['originalPackAmount'] = pack[0].amountAfterDiscount
          setPriceLoading(true)
          let webPackage = await PackageWebUIController(state);
          if (webPackage['status']) {
            setFinalAmountInr(webPackage.response.finalAmountInr);
            setPriceLoading(false)
          } else {
            alert(webPackage.message)
          }
        } else {
          Toastify("error","Sorry change tenure")
          setFinalAmountInr("Please choose pack")
          return
        }
      } else {
        // alert("Please choose pack")
      }
    } else {
      setFinalAmountInr('Please select pack')
    }
  }
  const onChangeHandler = async (e) => {
    const { value, name } = e.target;
    switch (name) {
      case 'firstName':
        setState((prevState) => {
          return {
            ...prevState,
            'firstName': value,
          };
        });
        break;
      case 'lastName':
        setState((prevState) => {
          return {
            ...prevState,
            'lastName': value,
          };
        });
        break;
      case 'gender':
        setState((prevState) => {
          return {
            ...prevState,
            'gender': value,
          };
        });
        break;
      case 'applicantDob':
        setState((prevState) => {
          return {
            ...prevState,
            'applicantDob': value,
          };
        });
        break;
      case 'appicantMobile':
        setState((prevState) => {
          return {
            ...prevState,
            'appicantMobile': value,
          };
        });
        break;
      case 'pan':
        setState((prevState) => {
          return {
            ...prevState,
            'pan': value,
          };
        });
        break;
      case 'email':
        setState((prevState) => {
          return {
            ...prevState,
            'email': value,
          };
        });
        break;
      case 'studentEmail':
        setState((prevState) => {
          return {
            ...prevState,
            'studentEmail': value,
          };
        });
        break;
      case 'couponCode':
        setState((prevState) => {
          return {
            ...prevState,
            'couponCode': value,
          };
        });
        break;
      case 'studentDob':
        setState((prevState) => {
          return {
            ...prevState,
            'studentDob': value,
          };
        });
        break;
      case 'relationship':
        setState((prevState) => {
          return {
            ...prevState,
            'relationship': value,
          };
        });
        break;
      case 'tenure':
        setState((prevState) => {
          return {
            ...prevState,
            'tenure': value,
          };
        });
        break;
      default:
        break;
    }
  }
  const customHandleChange = (e, setFieldValue) => {
    let { value, name } = e.target
    if (name === 'course') {
      setFieldValue('subject', "");
      setFieldValue('course', value);
    }
    if (name === 'email' && samAs) {
      setFieldValue("studentEmail", value);
      setFieldValue("email", value);
    }
  }
  const handleClose = () => setIsFieldError(false);

  const handleChecked = (e, setFieldValue, email) => {
    if (email) {
      setSameAs(e.target.checked)
      if (e.target.checked) {
        setFieldValue("studentEmail", email);
        setState((prevState) => {
          return {
            ...prevState,
            'studentEmail': email,
          };
        })
      } else {
        setFieldValue("studentEmail", "");
      }
    } else {
      setSameAs(false)
      // Toastify('error', 'Please fill applicant email')
    }
  }
  const onChangeCoupon = async (e ,setFieldValue) => {
    // const { value ,  name } = e.target;
    // setFieldValue("course","")
    // setFieldValue("subject","")
    // console.log(name,"@@@@@@@@@@@@" ,value)
  }
  return (
    <React.Fragment>
      <div className="financePeer">
        <Navbar />
        <div className="container mt-3">
          {isCreateLoanLoading ?
            <RotatingLinesLoader />
            :
            <div className="row financePeerform">
              <div className="col-md-6 offset-md-3">
                {/* .col-md-6 .offset-md-3 */}
                <div className="student-loan-form">
                  <div className="form-heading">
                    <h2>Financepeer</h2>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: '',
                      lastName: '',
                      gender: '',
                      city: '',
                      state: '',
                      country: '',
                      addressLine1: '',
                      addressLine2: '',
                      pincode: '',
                      course: '',
                      subject: '',
                      couponCode: 'DREAMTEAM',
                      appicantMobile: '',
                      email: '',
                      studentEmail: '',
                      pan: '',
                      tenure: '',
                      studentDob: '',
                      applicantDob: '',
                      fee: finalAmountInr,
                      leadName: '',
                      teamName: '',
                      relationship: '',
                      // role: role.role
                    }}
                    validationSchema={financePeerSchema}
                    onSubmit={async (values, { resetForm }) => {
                      values['fee'] = finalAmountInr
                      // values['tenure'] = state.defaultValidity
                      values['planID'] = state.planID
                      values['originalPackAmount'] = state.originalPackAmount
                      values['teamName'] = role['role']
                      values['planName'] = state.planName ? state.planName : ""
                      values['agentEmail'] = role.email
                      //confirm box
                      if (await confirm({ confirmation: 'Are you sure to submit this application ?' })) {
                        let course = courses.filter(course => course.id === parseInt(values.course))
                        if (course.length > 0) {
                          values['courseName'] = course[0]['course']
                        }
                        setCreateLoanLoading(true)
                        let createLoan = await createLoanApplication(values)
                        if (createLoan.status) {
                          setCreateLoanLoading(false)
                          Toastify('success', 'Submitted Successfully')
                          navigate("/listing");
                        } else {
                          setCreateLoanLoading(false)
                          Toastify('error', 'Something went wrong')
                          resetForm();
                          setFinalAmountInr('Please choose pack')
                        }
                      } else {
                        return;
                      }
                    }}
                  >
                    {({ handleSubmit, handleChange, setFieldValue, values, errors, touched, setTouched }) => (
                      // <form  class="row g-3" onSubmit={handleSubmit}>
                      //   {/* <div className="mb-3">
                      //     <label for="city" className="form-label">City</label>
                      //     <input type="text" className="form-control" id="city" name="city" onChange={handleChange} value={values.city} aria-describedby="city" />
                      //     {touched.city && errors.city ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.city}</div>
                      //     ) : null}
                      //   </div> */}
                      //   {/* <div className="mb-3">
                      //     <label for="state" className="form-label">State</label>
                      //     <input type="text" className="form-control" id="state" name="state" onChange={handleChange} value={values.state} aria-describedby="state" />
                      //     {touched.state && errors.state ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.state}</div>
                      //     ) : null}
                      //   </div> */}
                      //   {/* <div className="mb-3">
                      //     <label for="country" className="form-label">Country</label>
                      //     <input type="text" className="form-control" id="country" name="country" onChange={handleChange} value={values.country} aria-describedby="country" />
                      //     {touched.country && errors.country ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.country}</div>
                      //     ) : null}
                      //   </div> */}
                      //   {/* <div className="mb-3">
                      //     <label for="addressLine1" className="form-label">Address Line 1</label>
                      //     <input type="text" className="form-control" id="addressLine1" name="addressLine1" onChange={handleChange} value={values.addressLine1} aria-describedby="addressLine1" />
                      //     {touched.addressLine1 && errors.addressLine1 ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.addressLine1}</div>
                      //     ) : null}
                      //   </div>
                      //   <div className="mb-3">
                      //     <label for="addressLine2" className="form-label">Address Line 2</label>
                      //     <input type="text" className="form-control" id="addressLine2" name="addressLine2" onChange={handleChange} value={values.addressLine2} aria-describedby="addressLine2" />
                      //     {touched.addressLine2 && errors.addressLine2 ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.addressLine2}</div>
                      //     ) : null}
                      //   </div> */}
                      //   {/* <div className="mb-3">
                      //     <label for="pincode" className="form-label">PinCode</label>
                      //     <input type="text" className="form-control" id="pincode" name="pincode" onChange={handleChange} value={values.pincode} aria-describedby="pincode" />
                      //     {touched.pincode && errors.pincode ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.pincode}</div>
                      //     ) : null}
                      //   </div> */}
                      //     {/* <div className="mb-3">
                      //       <label for="leadName" className="form-label">Lead Name</label>
                      //       <input type="text" className="form-control" id="leadName" name="leadName" aria-describedby="leadName" onChange={handleChange} value={values.leadName} />
                      //       {touched.leadName && errors.leadName ? (
                      //         <div className="form-text" style={{ color: "red" }}>{errors.leadName}</div>
                      //       ) : null}
                      //     </div> */}
                      //   <div className="mb-3">
                      //     <label for="teamName" className="form-label">Team Name</label>
                      //     <select className="form-select" aria-label="Default select example" name="teamName" onChange={handleChange} value={values.teamName}>
                      //       <option selected>Select Team Name</option>
                      //       <option value="ist">IST</option>
                      //       <option value="bd">BD</option>
                      //     </select>
                      //     {touched.teamName && errors.teamName ? (
                      //       <div className="form-text" style={{ color: "red" }}>{errors.teamName}</div>
                      //     ) : null}
                      //   </div>
                      //   <button type="submit" className="btn btn-primary">Submit</button>
                      // </form>
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6">
                          <label for="firstName" className="form-label">First Name</label>
                          <input type="text" className="form-control" id="firstName" name="firstName" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['firstName']} aria-describedby="firstName" />
                          {touched.firstName && errors.firstName ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.firstName}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <label for="lastName" className="form-label">Last Name</label>
                          <input type="text" className="form-control" id="lastName" name="lastName" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['lastName']} aria-describedby="lastName" />
                          {touched.lastName && errors.lastName ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.lastName}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="gender" className="form-label">Gender</label>
                          <select className="form-select" aria-label="Default select example" name="gender" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['gender']}>
                            <option>Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                          {touched.gender && errors.gender ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.gender}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="appicantDob" className="form-label">Applicant Dob</label>
                          <DatePicker
                            name='applicantDob'
                            className="form-control"
                            format='y-MM-dd'
                            value={values['applicantDob']}
                            onChange={(e) => {
                              setFieldValue('applicantDob', e);
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  'applicantDob': e,
                                };
                              });
                            }}
                          />
                          {/* <input type="text" className="form-control" id="dob" name="dob" aria-describedby="dob" onChange={handleChange} value={values.dob} /> */}
                          {touched.applicantDob && errors.applicantDob ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.applicantDob}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="appicantMobile" className="form-label">Applicant Mobile</label>
                          <input type="text" className="form-control" id="appicantMobile" name="appicantMobile" aria-describedby="appicantMobile" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['appicantMobile']} />
                          {touched.appicantMobile && errors.appicantMobile ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.appicantMobile}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="pan" className="form-label">PAN</label>
                          <input type="text" className="form-control" id="pan" name="pan" aria-describedby="pan" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['pan'].toUpperCase()} />
                          {touched.pan && errors.pan ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.pan}</div>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <label for="email" className="form-label">Applicant Email</label>
                          <input type="email" className="form-control" id="email" name="email" aria-describedby="email" onChange={(e) => { handleChange(e); onChangeHandler(e); customHandleChange(e, setFieldValue) }} value={values['email']} />
                          {touched.email && errors.email ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="sameAs" id="sameAs" onChange={(e) => { handleChecked(e, setFieldValue, values['email']) }} checked={values['sameAs']} />
                            <label className="form-check-label" for="sameAs">
                              Same as Applicant email
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <label for="studentEmail" className="form-label">Student Email</label>
                          <input type="email" className="form-control" id="studentEmail" name="studentEmail" aria-describedby="studentEmail" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['studentEmail']} />
                          {touched.couponCode && errors.studentEmail ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.studentEmail}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="relationship" className="form-label">Coupon Code</label>
                          <input type="text" className="form-control" id="couponCode" name="couponCode" aria-describedby="couponCode" onChange={(e) => { handleChange(e); onChangeHandler(e);}} value={values['couponCode']} />
                          {touched.couponCode && errors.couponCode ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.couponCode}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="studentDob" className="form-label">Student Dob</label>
                          <DatePicker
                            name='studentDob'
                            className='form-control'
                            format='y-MM-dd'
                            value={values['studentDob']}
                            onChange={(e) => {
                              setFieldValue('studentDob', e);
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  'studentDob': e,
                                };
                              });
                            }}
                          />
                          {/* <input type="text" className="form-control" id="dob" name="dob" aria-describedby="dob" onChange={handleChange} value={values.dob} /> */}
                          {touched.studentDob && errors.studentDob ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.studentDob}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="tenure" className="form-label">Tenure</label>
                          <select className="form-select" id="tenure" name="tenure" aria-label="tenure" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['tenure']}>
                            <option value="">Please select Tenure</option>
                            <option value="3">3</option>
                            <option value="6">6</option>
                            <option value="9">9</option>
                            <option value="12">12</option>
                          </select>
                          {touched.tenure && errors.tenure ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.tenure}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label for="relationship" className="form-label">Relationship</label>
                          <input type="text" className="form-control" id="relationship" name="relationship" aria-describedby="relationship" onChange={(e) => { handleChange(e); onChangeHandler(e) }} value={values['relationship'].toUpperCase()} />
                          {touched.relationship && errors.relationship ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.relationship}</div>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <label for="country" className="form-label">States</label>
                          {/* <input type="text" className="form-control" id="country" name="country" onChange={handleChange} value={values.country} aria-describedby="country" /> */}
                          <select className="form-select" id="state" name="state" aria-label="state" onChange={handleChange} value={values.state}>
                            <option value="">Please select States</option>
                            {states.map((state, index) => {
                              return (
                                <option key={state.id} value={state.name}>{state.name}</option>
                              )
                            })}
                          </select>
                          {touched.state && errors.state ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.state}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12">
                          <label for="course" className="form-label">Course</label>
                          <select className="form-select" aria-label="course" name="course" onChange={(e) => { handleChange(e); courseHandelChange(e); customHandleChange(e, setFieldValue) }} value={values['course']} >
                            <option value="">Select Course</option>
                            {courses.map((course, index) => {
                              return (
                                <option key={course.id} value={course.id}>{course.course}</option>
                              )
                            })}
                          </select>
                          {touched.course && errors.course ? (
                            <div className="form-text" style={{ color: "red" }}>{errors.course}</div>
                          ) : null}
                        </div>
                        {isPackLoading ?
                          <div className="col-md-12">
                            <ColorRingLoader />
                          </div> :
                          <div className="col-md-12">
                            <label for="course" className="form-label">Select Pack</label>
                            <select className="form-select" aria-label="subject" name="subject" onChange={(e) => { handleChange(e); packHandelChange(e); }} value={values['subject']}>
                              <option value="">Please Select</option>
                              {packs.map((pack) => {
                                return (
                                  <>
                                    <option key={pack.subscriptionID} value={pack.subscriptionID}>{pack.planName}</option>
                                  </>
                                )
                              })}
                            </select>
                            {touched.subject && errors.subject ? (
                              <div className="form-text" style={{ color: "red" }}>{errors.subject}</div>
                            ) : null}
                          </div>
                        }
                        {isPriceLoading ?
                          <div className="col-md-12">
                            <ColorRingLoader />
                          </div> :
                          <div className="col-6">
                            <label for="fee" className="form-label">Loan Amount</label>
                            <input type="text" className="form-control" id="fee" name="fee" aria-describedby="fee" onChange={handleChange} value={finalAmountInr} readOnly />
                            {touched.fee && errors.fee ? (
                              <div className="form-text" style={{ color: "red" }}>{errors.fee}</div>
                            ) : null}
                          </div>
                        }
                        {/* <div class="col-md-2">
                   <label for="inputZip" class="form-label">Zip</label>
                   <input type="text" class="form-control" id="inputZip" />
                 </div>
                 <div class="col-12">
                   <div class="form-check">
                     <input class="form-check-input" type="checkbox" id="gridCheck" />
                     <label class="form-check-label" for="gridCheck">
                       Check me out
                     </label>
                   </div>
                 </div> */}
                        <div className="col-12 mt-5">
                          <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                    )}
                  </Formik>

                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {isFieldError ? <ErrorPopup show={isFieldError} handleClose={handleClose} errorMessage={errorMessage} /> : ""}
    </React.Fragment >
  )
}

