import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from "moment";
import Table from './Table';
import { Navbar } from '../../components/Navbar';
import { fetchStudentLoanApplication } from './financepeer.services';
import { RotatingLinesLoader } from '../../components/loader/RotatingLinesLoader';
import './financepeer.styles.css';
import Button from 'react-bootstrap/Button';
import { StatusPopup } from '../../components/modal/StatusPopup';
import { loggedInUser } from '../../utils/auth';
import { Toastify } from '../../components/toaster/Toastify';



const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      ${'' /* :first-child {
        td {
          border-top: 1px solid black;
        }
      } */}

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    tfoot {
      tr:first-child {
        td {
          border-top: 2px solid black;
        }
      }
      font-weight: bolder;
    }
  }
  .pagination button  {
    margin: 0px 3px;
  }
`


const Financepeer = () => {
  const [data, setData] = useState([])
  const [loanId, setLoanId] = useState(null)
  const [isStatusModal, setIsStatusModal] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getStudentLoanApplication()
  }, [])

  const getStudentLoanApplication = async () => {
    setLoading(true)
    let user = await loggedInUser();
    let loanApplication = await fetchStudentLoanApplication({ email: user.email, role: user.role });
    if (loanApplication.status) {
      setData(loanApplication.response)
      setLoading(false)
    }
  }

  function DateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = moment(preFilteredRows[0].values[id])
      let max = moment(preFilteredRows[0].values[id])
      // 
      preFilteredRows.forEach(row => {
        min = moment.min(moment(row.values[id]), min)
        max = moment.max(moment(row.values[id]), max)
      });
      return [min, max];
    }, [id, preFilteredRows])
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="date"
          // id="startDate"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? (val) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '50%',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="date"
          // id="endDate"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? (val) : undefined])
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '50%',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    )
  }

  const handleStatus = (row) => {
    setLoanId(row.loanId)
    setIsStatusModal(true)
  }
  const handleLinkCopy = async (row) => {
    // navigator.clipboard.writeText(row.loginUrl)
    navigator.clipboard.writeText(row.loginUrl).then(
      () => {
        Toastify("success", "Copied")
        // setCopySuccess("Copied!");
      },
      () => {
        Toastify("error", "failed")
        // setCopySuccess("Copy failed!");
      }
    );

  }
  const handleClose = () => setIsStatusModal(false);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        Footer: 'Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
            Footer: 'First Name',
            disableFilters: true,
            Filter: <input type="hidden" />

          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
            Footer: 'Last Name',
            disableFilters: true,
            Filter: <input type="hidden" />
          },
        ],
      },
      {
        Header: 'Info',
        Footer: 'Info',
        columns: [
          // {
          //   Header: 'ApplicantEmail',
          //   accessor: 'applicantEmail',
          //   Footer: 'ApplicantEmail',
          //   disableFilters: true,
          //   Filter: <input type="hidden" />
          // },
          {
            Header: 'Learner Email',
            accessor: 'learnerEmail',
            Footer: 'Learner Email',
            disableFilters: true,
            Filter: <input type="hidden" />
          },
          {
            Header: 'Phone',
            accessor: 'phone',
            Footer: 'Phone',
            disableFilters: true,
            Filter: <input type="hidden" />
          },
          {
            Header: 'Amount',
            accessor: 'amount',
            // Footer: 'Amount',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Footer: info => {
              // Only calculate total visits if rows change
              const total = React.useMemo(
                () =>
                  info.rows.reduce((sum, row) => row.values.amount + sum, 0),
                [info.rows]
              )

              return <>Total: {total}</>
            },
          },
          {
            Header: 'Coupon Code',
            accessor: 'couponCode',
            Footer: 'Coupon Code',
            disableFilters: true,
            Filter: <input type="hidden" />
          },
          // {
          //   Header: 'Notes',
          //   accessor: 'notes',
          //   Footer: 'Notes',
          //   disableFilters: true,
          //   Filter: <input type="hidden" />
          // },
          {
            Header: 'Loan ID',
            accessor: 'loanId',
            Footer: 'Loan ID',
            disableFilters: true,
            Filter: <input type="hidden" />
          },
          {
            Header: 'Login Url',
            accessor: 'loginUrl',
            Footer: 'Login Url',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Cell: row => (
              <div>
                {/* <button onClick={e=> handleStatus(row.row.original)}>Edit</button> */}
                <Button variant="success" onClick={e => handleLinkCopy(row.row.original)}>Copy</Button>{' '}
              </div>
            )
          },
          {
            Header: 'Loan Status',
            accessor: 'status',
            Footer: 'Loan Status',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Cell: row => (
              <div>
                {/* <button onClick={e=> handleStatus(row.row.original)}>Edit</button> */}
                <Button variant="secondary" onClick={e => handleStatus(row.row.original)}>View</Button>{' '}
              </div>
            )
          },
          {
            Header: 'Pack Status',
            accessor: 'packStatus',
            Footer: 'Pack Status',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Cell: ({ row: { original } }) => (
              original.packStatus === "NOT ACTIVATED" ?
                <>
                  <span class="badge bg-warning text-dark">{original.packStatus}</span>
                </>
                :
                original.packStatus === "PACK ACTIVATED" ?
                  <>
                    <span class="badge bg-success">{original.packStatus}</span>
                  </>
                  :
                  original.packStatus === "EXPIRED" ?
                    <>
                      <span class="badge bg-danger">{original.packStatus}</span>
                    </>
                    :
                    <>
                      <span class="badge bg-info text-dark">{original.packStatus}</span>
                    </>
            )
          },
          {
            Header: 'Loan last status',
            accessor: 'loanLastStatus',
            Footer: 'Loan last status',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Cell: row => (
              <div>
                {row.row.original.loanLastStatus ? row.row.original.loanLastStatus : "NA"}
              </div>
            )
          },
          {
            Header: 'Loan last createdAt',
            accessor: 'loanLastCreatedAt',
            Footer: 'Loan last createdAt',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Cell: row => (
              <div>
                {row.row.original.loanLastCreatedAt ? row.row.original.loanLastCreatedAt : "NA"}
              </div>
            )
          },
          {
            Header: 'DisbursalUtr',
            accessor: 'disbursalUtr',
            Footer: 'DisbursalUtr',
            disableFilters: true,
            Filter: <input type="hidden" />,
            Cell: (row) => {
              const bodyJson = JSON.parse(row.row.original.loanLastBodyJson)
              if (bodyJson) {
                if (bodyJson.disbursalUtr) {
                  return bodyJson.disbursalUtr
                } else {
                  return "NA"
                }
              } else {
                return "NA"
              }
            }
          },
          {
            Header: 'Date',
            accessor: 'date',
            Filter: DateRangeColumnFilter,
            filter: "dateBetween", /* Custom Filter Type */
            Footer: 'Date',
          }
        ],
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="student-enquiries">
        <Navbar />
        <>
          {loading ?
            <RotatingLinesLoader />
            :
            <>
              {
                data.length > 0 ?
                  <div className='enquiries-listing'>
                    <Styles>
                      <Table columns={columns} data={data} />
                    </Styles>
                  </div>
                  :
                  <div className="nodatafound" style={{ textAlign: "center", marginTop: "10px" }}>
                    <h2>No data found</h2>
                  </div>
              }
            </>
          }
        </>
      </div>
      {isStatusModal ? <StatusPopup show={isStatusModal} handleClose={handleClose} loanId={loanId} /> : ""}
    </React.Fragment>
  )
}

export default Financepeer
