let API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === "dev" ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_API_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_LOCAL

export const loginFoundationPack = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}loginFoundationPack`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}


export const createFoundationPackUser = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}createFoundationPackUser`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchFoundationPackUsers = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchFoundationPackUsers`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}