import { router } from "./routes/router";
import {
  RouterProvider,
} from "react-router-dom";
import { ToasterContainer } from "./components/toaster/ToasterContainer";


function App() {
  return (
    <div className="financepeer">
      <RouterProvider router={router} />
      <ToasterContainer/>
    </div>
  );
}

export default App;
