let API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === "dev" ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_API_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_LOCAL


export const generatePassword = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}generatePassword`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchUsers = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchUsers`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchUser = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchUser/${body.id}`, {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const updateUser = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}updateUser/${body.id}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}