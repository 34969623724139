import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { studentLoanApplicationStatus } from '../../features/financepeer/financepeer.services';
import { InfinitySpinner } from '../loader/InfinitySpinner';

export const StatusPopup = ({ show, handleClose, loanId }) => {
    const [loanStatus, setLoanStatus] = useState([])
    const [isLoanStatusLaoding, setIsLoanStatusLaoding] = useState(false)
    useEffect(() => {
        fetchStudentLoanStatus()
        setIsLoanStatusLaoding(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetchStudentLoanStatus = async () => {
        let loanStatus = await studentLoanApplicationStatus({
            loanId: loanId
        })
        if (loanStatus.status) {
            setIsLoanStatusLaoding(false)
            let loanApplicationArray = []
            let loanApplication = loanStatus.response
            loanApplication.forEach((loan) => {
                let body = JSON.parse(loan.bodyJson)
                let obj = {}
                obj['id'] = loan.id
                obj['loanAmount'] = body.loanAmount
                obj['loanId'] = body.application_id
                obj['loanStatus'] = body.application_status
                obj['remarks'] = body.remarks
                obj['couponCode'] = body.couponCode
                obj['studentEmail'] = body.studentEmail
                obj['disbursalUtr'] = body.disbursalUtr ? body.disbursalUtr : "NA"
                obj['date'] = loan.date
                loanApplicationArray.push(obj)
            })
            setLoanStatus(loanApplicationArray)
        } else {
            setIsLoanStatusLaoding(true)
        }
    }
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Loan status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='loanstatus-table' style={{ overflow: 'auto' }}>
                        {isLoanStatusLaoding ?
                            <InfinitySpinner /> :
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Loan ID</th>
                                        <th>Loan Amount</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th>Coupon Code</th>
                                        <th>Student Email</th>
                                        <th>disbursalUtr</th>
                                        <th>createdAt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loanStatus.length > 0 ?
                                        <>
                                            {loanStatus.map((loan) => {
                                                return (
                                                    <tr>
                                                        <td>{loan.id}</td>
                                                        <td>{loan.loanId}</td>
                                                        <td>{loan.loanAmount}</td>
                                                        <td>{loan.loanStatus}</td>
                                                        <td>{loan.remarks}</td>
                                                        <td>{loan.couponCode}</td>
                                                        <td>{loan.studentEmail}</td>
                                                        <td>{loan.disbursalUtr ? loan.disbursalUtr : "NA"}</td>
                                                        <td>{loan.date}</td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <tr>
                                            <td className="no-record-status" colspan="9" style={{ textAlign: "center" }}>
                                                No data available
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
