
import React, { useEffect, useRef, useState } from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table'
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { loggedInUser } from '../../utils/auth';


const Table = ({ columns, data }) => {
  const [user, setUser] = useState({})
  useEffect(() => {
    loggedUser()
  }, [])

  const csvLinkRef = useRef(null);
  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = moment(filterValues[0]).format('YYYY-MM-DD');
    let ed = moment(filterValues[1]).format('YYYY-MM-DD');
    return rows.filter(r => {
      var time = moment(r.values[id]).format('YYYY-MM-DD');
      if (filterValues[0] === undefined && filterValues[1] === undefined) {
        return rows
      }
      if (time >= sd && time <= ed) {
        return r
      }

    });
  }
  dateBetweenFilterFn.autoRemove = val => !val;

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn,   /*<- LIKE THIS*/
      text: (rows, id, filterValue) => {
        console.log("filterValue filterValue", filterValue)
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    //pagination
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
      defaultColumn,
      filterTypes,
      //pagination
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    // { label: "Applicant Email", key: "applicantEmail" },
    { label: "Learner Email", key: "learnerEmail" },
    { label: "Phone", key: "phone" },
    { label: "Amount", key: "amount" },
    { label: "Coupon Code", key: "couponCode" },
    // { label: "Notes", key: "notes" },
    { label: "Loan ID", key: "loanId" },
    { label: "Pack Status", key: "packStatus" },
    { label: "Date", key: "date" }
  ];

  const downloadReport = async () => {
    csvLinkRef.current.link.click()
  }
  const loggedUser = async () => {
    let user = await loggedInUser()
    setUser(user)
  }
  return (
    <React.Fragment>
      <div className="container">
        <div className="row" style={{ marginBottom: '15px', marginTop: '15px' }}>
          <div className="col-md-6 listing-button">
            <button type="button" className="btn btn-secondary" onClick={() => { downloadReport() }}>
              Export to CSV
              <CSVLink
                headers={headers}
                filename="Student_enquiries.csv"
                data={data}
                ref={csvLinkRef}
              />
            </button>
            {/* {user.role === "admin" | user.role === "istAdmin" || user.role === "bdAdmin" ?
              <Link to="/users" className="btn btn-secondary" style={{ marginLeft: "12px" }}>
                Users
              </Link>
              :
              ""
            } */}
          </div>
          <div className="col-md-2 offset-md-4">
            <input type="text" className='form-control' name="gloablaSearch" placeholder='Search' value={state.globalFilter || ''} onChange={e => {
              const val = e.target.value
              setGlobalFilter(val)
            }} />
          </div>
        </div>
        {/* <div className="row">
        <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
              pageOptions
            },
            null,
            2
          )}
        </code>
      </pre>
        </div> */}
        <div className="financepeer-listing">
          <table {...getTableProps()} style={{ width: '100%' }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (

                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>

                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <br />
        {/* <div>Showing the first 2 results of {rows.length} rows</div> */}
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </React.Fragment>

  )
}

export default Table;