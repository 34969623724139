import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { createPackUsersSchema } from '../../validations/createPackUsersValidation';
import { createFoundationPackUser } from '../../features/foundationPackUsers/foundationPackServices';
import { foundationLoggedInUser } from '../../utils/foundationPackAuth';
import { useNavigate, useParams, Link } from "react-router-dom";
import { decryption } from '../../utils/bycrypt.helper';
import { Toastify } from '../../components/toaster/Toastify';
import { LightSpinner } from '../../components/loader/LightSpinner';
import { FoundationNavbar } from '../../components/navbar/FoundationNavbar';


export const CreateFoundationPackUser = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [teamType, setTeamType] = useState("")
    useEffect(() => {
        isAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isAuth = async () => {
        try {
            let teamType = await decryption(params.teamType);
            setTeamType(params.teamType)
            let loggerInUser = await foundationLoggedInUser();
            if (teamType === loggerInUser.role) {
                setLoggedInUser(loggerInUser);
            } else {
                navigate('/*')
            }
        } catch (error) {
            navigate('/*')
        }
    }
    const backTolisting = () => {
        navigate(`/foundation-pack-users/${teamType}`)
    }
    return (
        <React.Fragment>
            <div className="create-foundation-pack">
                <FoundationNavbar teamType={teamType} />
                <div className="pt-5">
                    {/* {loading ? <InfinitySpinner />
        : */}
                    <div className="global-container">
                        <div className="card login-form">
                            <div className="card-body">
                                <Link to={`/foundation-pack-users/${teamType}`} style={{ color: "lightseagreen" }}>
                                    <i class="fa fa-arrow-left fa-2x" aria-hidden="true" onClick={() => { backTolisting() }}></i>
                                </Link>
                                <h3 className="card-title text-center">  Create Foundation Pack </h3>
                                <div className="card-text">
                                    <Formik
                                        initialValues={{
                                            email: ''
                                        }}
                                        validationSchema={createPackUsersSchema}
                                        onSubmit={async (values, { resetForm }) => {
                                            values['teamID'] = loggedInUser.id
                                            values['teamType'] = loggedInUser.role
                                            values['teamEmail'] = loggedInUser.email
                                            setLoading(true)
                                            let createFoundation = await createFoundationPackUser(values);
                                            if (createFoundation.status) {
                                                setLoading(false)
                                                resetForm({})
                                                Toastify('success', 'Pack create Successfully')
                                                // navigate(`/create-foundation-pack-users/${params.teamType}`)
                                            } else {
                                                setLoading(false)
                                                Toastify('error', createFoundation.message);
                                            }
                                        }}
                                    >
                                        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleChange} value={values['email']} />
                                                    {touched.email && errors.email ? (
                                                        <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3 d-grid">


                                                    {loading ?
                                                        <button className="btn" type="button">
                                                            <LightSpinner />
                                                        </button>
                                                        :
                                                        <button className="btn" type="submit">Submit</button>
                                                    }
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* } */}
                </div>
            </div>
        </React.Fragment>
    )
}
