import React, { useEffect, useState } from 'react'
import prepLogo from "../../prepladder-logo.png"
import { Link, useNavigate } from "react-router-dom";
import { CreateFoundationPackUsers } from '../modal/CreateFoundationPackUsers';
import { Toastify } from '../toaster/Toastify';

export const FoundationNavbar = ({ teamType }) => {
    const navigate = useNavigate()
    const [createPackShow, setCreatePackShow] = useState(false);
    const handleClose = () => setCreatePackShow(false);
    const handleShow = () => setCreatePackShow(true);
    const logout = () => {
        navigate(`/login/${teamType}`);
        localStorage.removeItem('foundationpack')
        Toastify('success','Logout Succesfully')
    }
    return (
        <React.Fragment>
            <div className="foundation-navbar">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <Link className="navbar-brand">
                            <img
                                src={prepLogo}
                                height="40"
                                alt="MDB Logo"
                                loading="lazy"
                            />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    {/* <Link className="nav-link active" aria-current="page" onClick={() => { handleShow() }}>Create</Link> */}
                                    <Link className="nav-link active" aria-current="page" to={`/create-foundation-pack-users/${teamType}`}>Create</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to={`/foundation-pack-users/${teamType}`}>Listing</Link>
                                </li>


                            </ul>
                            <form className="d-flex">
                                {/* <i className="fa-solid fa-right-from-bracket">xcvxv</i> */}
                                <button className="btn btn-outline-danger" onClick={() => { logout() }} type="button">Logout</button>
                                {/* <button className="btn btn-outline-success" type="submit">Search</button> */}
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
            {createPackShow ? <CreateFoundationPackUsers show={createPackShow} handleClose={handleClose} /> : ""}
        </React.Fragment>
    )
}
