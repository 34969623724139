import { decryption } from "./bycrypt.helper";

export const isFoundationAuthenticated = () => {
    let user = localStorage.getItem("foundationpack");
    if (user) {
        return true
    } else {
        return false
    }
}

export const foundationLoggedInUser =async () => {
    let user = localStorage.getItem("foundationpack");
    let decrypt = await decryption(user);
    return decrypt;
}