import React, { useEffect, useState } from 'react'
import {
    useParams
} from "react-router-dom";
import { Formik } from 'formik';
import { decryption, encryption } from '../../utils/bycrypt.helper';
import { Toastify } from '../../components/toaster/Toastify';
import { loginFoundationPack } from '../foundationPackUsers/foundationPackServices';
import { foundationSignInSchema } from '../../validations/foundationLoginValidation';
import { useNavigate } from "react-router-dom";
import { LightSpinner } from '../../components/loader/LightSpinner';
import { isFoundationAuthenticated } from '../../utils/foundationPackAuth';

export const FoundationPackUserLogin = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [teamType, setTeamType] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchLoginData();
    }, [])

    const fetchLoginData = async () => {
        try {
            let teamType = await decryption(params.teamType)
            if (teamType === 'ist' || teamType === 'bd') {
                setTeamType(teamType)
                let authenticated = await isFoundationAuthenticated()
                if (authenticated) {
                    navigate(`/create-foundation-pack-users/${params.teamType}`)
                }
            } else {
                Toastify("error", "Something went wrong")
            }
        } catch (error) {
            console.log(params.teamType ,"Error >>>>>>>" ,error)
            navigate('/*')
            Toastify("error", "Something went wrong")
        }
    }
    return (
        <React.Fragment>
            <div className="pt-5">
                {/* {loading ? <InfinitySpinner />
                    : */}
                <div className="global-container">
                    <div className="card login-form">
                        <div className="card-body">
                            <h3 className="card-title text-center"> {teamType === "ist" ? "IST" : "BD"} Login</h3>
                            <div className="card-text">
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: ''
                                    }}
                                    validationSchema={foundationSignInSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        values['role'] = teamType
                                        setLoading(true)
                                        let user = await loginFoundationPack(values);
                                        if (user.status) {
                                            setLoading(false)
                                            Toastify('success', 'Login successfully')
                                            let encrypt = await encryption(user.response[0])
                                            localStorage.setItem("foundationpack", encrypt);
                                            navigate(`/create-foundation-pack-users/${params.teamType}`);
                                        } else {
                                            setLoading(false)
                                            Toastify('error', user.message)
                                        }
                                    }}
                                >
                                    {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label for="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="email" placeholder="Email" name="email" onChange={handleChange} value={values['email']} />
                                                {touched.email && errors.email ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label for="password" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="password" placeholder="Password" name="password" onChange={handleChange} value={values['password']} />
                                                {touched.password && errors.password ? (
                                                    <div className="form-text" style={{ color: "red" }}>{errors.password}</div>
                                                ) : null}
                                            </div>
                                            {/* <div class="form-check">
                                   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                   <label class="form-check-label" for="flexCheckDefault">
                                       Remember me
                                   </label>
                               </div> */}
                                            <div className="mb-3 d-grid">
                                                {loading ?
                                                    <button className="btn" type="button">
                                                        <LightSpinner />
                                                    </button>
                                                    :
                                                    <button className="btn" type="submit">Login</button>
                                                }

                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                {/* } */}
            </div>
        </React.Fragment>
    )
}
