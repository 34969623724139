
let API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === "dev" ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_API_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_LOCAL


export const createLoanApplication = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}createStudentLoanApplication`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchStudentLoanApplication = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchStudentLoanApplication`, {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(body),
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const studentLoanApplicationStatus = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}studentLoanApplicationStatus`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchCourses = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchCourses`, {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchCoursePlans = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchCoursePlans`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const PackageWebUIController = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}PackageWebUIController`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const login = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}login`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchCoursePlanName = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchCoursePlanName`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchStates = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchStates`, {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}

export const fetchFoundationPackUsers = async (body) => {
    try {
        const response = await fetch(`${API_BASE_URL}fetchFoundationPacks`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        }
        );
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }
        const data = await response.json();
        return data;
    } catch (error) {
        console.log("Error +++++++", error)
    }
}